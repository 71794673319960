export { matchers } from './client-matchers.js';

export const components = [
	() => import("../../src/routes/__layout.svelte"),
	() => import("../runtime/components/error.svelte"),
	() => import("../../src/routes/account/gh/drivingpassportceleryanaconda/[user].svelte"),
	() => import("../../src/routes/account/ml/[encoded].svelte"),
	() => import("../../src/routes/account.svelte"),
	() => import("../../src/routes/blog/3-days-left-extension.svelte"),
	() => import("../../src/routes/blog/codefund-revenue-slice.svelte"),
	() => import("../../src/routes/blog/codejam-fly-1.svelte"),
	() => import("../../src/routes/blog/codejam-fly-2.svelte"),
	() => import("../../src/routes/blog/codejam-fly-3.svelte"),
	() => import("../../src/routes/blog/codejam-is-go.svelte"),
	() => import("../../src/routes/blog/dissecting-payday.svelte"),
	() => import("../../src/routes/blog/disseminating-first-pledge.svelte"),
	() => import("../../src/routes/blog/double-dip.svelte"),
	() => import("../../src/routes/blog/not-giving-up.svelte"),
	() => import("../../src/routes/blog/overtime.svelte"),
	() => import("../../src/routes/blog/pitch-deck.svelte"),
	() => import("../../src/routes/blog/simplified-terms.svelte"),
	() => import("../../src/routes/blog.svelte"),
	() => import("../../src/routes/change-log.svelte"),
	() => import("../../src/routes/codefund.svelte"),
	() => import("../../src/routes/codejam.svelte"),
	() => import("../../src/routes/codejamspace.svelte"),
	() => import("../../src/routes/codepie.svelte"),
	() => import("../../src/routes/contact.svelte"),
	() => import("../../src/routes/cookie.svelte"),
	() => import("../../src/routes/cryptomint.svelte"),
	() => import("../../src/routes/cybermine.svelte"),
	() => import("../../src/routes/dip.svelte"),
	() => import("../../src/routes/everlegend.svelte"),
	() => import("../../src/routes/faq.svelte"),
	() => import("../../src/routes/favvs.svelte"),
	() => import("../../src/routes/fund/[project].svelte"),
	() => import("../../src/routes/fund/index.svelte"),
	() => import("../../src/routes/hashbook.svelte"),
	() => import("../../src/routes/hashledger.svelte"),
	() => import("../../src/routes/index.svelte"),
	() => import("../../src/routes/jamicom.svelte"),
	() => import("../../src/routes/jamjar.svelte"),
	() => import("../../src/routes/machinecode.svelte"),
	() => import("../../src/routes/mindfolder.svelte"),
	() => import("../../src/routes/ml/[encoded].svelte"),
	() => import("../../src/routes/mountain.svelte"),
	() => import("../../src/routes/pledge.svelte"),
	() => import("../../src/routes/slog.svelte"),
	() => import("../../src/routes/stackfire.svelte"),
	() => import("../../src/routes/terms.svelte"),
	() => import("../../src/routes/zombie-dda.svelte")
];

export const dictionary = {
	"": [[0, 36], [1]],
	"account": [[0, 4], [1]],
	"blog": [[0, 18], [1]],
	"change-log": [[0, 19], [1]],
	"codefund": [[0, 20], [1]],
	"codejam": [[0, 21], [1]],
	"codejamspace": [[0, 22], [1]],
	"codepie": [[0, 23], [1]],
	"contact": [[0, 24], [1]],
	"cookie": [[0, 25], [1]],
	"cryptomint": [[0, 26], [1]],
	"cybermine": [[0, 27], [1]],
	"dip": [[0, 28], [1]],
	"everlegend": [[0, 29], [1]],
	"faq": [[0, 30], [1]],
	"favvs": [[0, 31], [1]],
	"fund": [[0, 33], [1]],
	"hashbook": [[0, 34], [1]],
	"hashledger": [[0, 35], [1]],
	"jamicom": [[0, 37], [1]],
	"jamjar": [[0, 38], [1]],
	"machinecode": [[0, 39], [1]],
	"mindfolder": [[0, 40], [1]],
	"mountain": [[0, 42], [1]],
	"pledge": [[0, 43], [1]],
	"slog": [[0, 44], [1]],
	"stackfire": [[0, 45], [1]],
	"terms": [[0, 46], [1]],
	"zombie-dda": [[0, 47], [1]],
	"blog/3-days-left-extension": [[0, 5], [1]],
	"blog/codefund-revenue-slice": [[0, 6], [1]],
	"blog/codejam-fly-1": [[0, 7], [1]],
	"blog/codejam-fly-2": [[0, 8], [1]],
	"blog/codejam-fly-3": [[0, 9], [1]],
	"blog/codejam-is-go": [[0, 10], [1]],
	"blog/dissecting-payday": [[0, 11], [1]],
	"blog/disseminating-first-pledge": [[0, 12], [1]],
	"blog/double-dip": [[0, 13], [1]],
	"blog/not-giving-up": [[0, 14], [1]],
	"blog/overtime": [[0, 15], [1]],
	"blog/pitch-deck": [[0, 16], [1]],
	"blog/simplified-terms": [[0, 17], [1]],
	"account/gh/drivingpassportceleryanaconda/[user]": [[0, 2], [1]],
	"account/ml/[encoded]": [[0, 3], [1]],
	"fund/[project]": [[0, 32], [1]],
	"ml/[encoded]": [[0, 41], [1]]
};